import React, {useState, useEffect, useMemo} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck} from '@fortawesome/free-solid-svg-icons'

const Plans = ({name, plans, signup, setSignup, setServerErrors, csrf_token, addons, ...props}) => {
  const category = new URLSearchParams(document.location.search).get('category')
  const categories = plans.map(plan => plan.category.toLowerCase().split(',')).flat()
  const [selectedCategory, setSelectedCategory] = useState(categories.includes(category) ? category : 'residential')
  const [selectedPlanId, setSelectedPlanId] = useState(() => {
    if (signup.plan_id) return signup.plan_id
    return null
  })
  const [selectedAddons, setSelectedAddons] = useState(() => {
    return signup.addon_uids ? signup.addon_uids.split(',') : []
  })
  const [wasSelectedAddons, setWasSelectedAddons] = useState(() => {
    return signup.addon_uids ? signup.addon_uids.split(',') : []
  })

  const [submitting, setSubmitting] = useState(null)

  useEffect(() => {
    if (!signup.uuid) props.goToStep(1)
  }, [])

  const selectServicePlan = async selectedPlanId => {
    if (submitting) {
      return
    }

    setServerErrors(null)
    setSubmitting(true)
    const response = await fetch(`/service_signup/${name}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token,
      },
      body: JSON.stringify({
        service_signup: {
          ...signup,
          signup_state: 'plan',
          plan_id: selectedPlanId,
          addon_uids: selectedAddons.join(','),
        },
      }),
    })

    let respData = await response.json()
    if (respData !== null) {
      if (respData.data) {
        setSignup(respData.data)
      }
      setSubmitting(false)
      if (respData.errors) {
        setServerErrors(respData.errors)
      } else {
        props.nextStep()
      }
    }
  }

  const Card = ({plan, children}) => {
    return (
      <div className="card">
        <div className="card-header px-0 py-0">
          <h5 className="card-title py-3 px-2 text-white text-center w-100 bg-primary my-0">{plan.name}</h5>
        </div>
        <div className="card-body text-white bg-secondary px-3 py-3 text-center">
          <p className="card-text text-white h1 mb-1">
            <b>
              <span className="h3 align-top">$</span>
              {plan.price % 1 === 0 ? plan.price : plan.price.toFixed(2)}
            </b>
            <span className="h4"> / mo.</span>
          </p>
          {plan.description ? <div dangerouslySetInnerHTML={{__html: plan.description}} /> : ''}
          <div className="card-image mt-3">
            <img src={plan.image} />
          </div>
        </div>
        {children}
      </div>
    )
  }

  const filteredPlans = plans => {
    if (signup.category) {
      return plans.filter(plan => plan.category.toLowerCase().split(',').includes(selectedCategory))
    }

    return plans
  }

  const SelectedPlan = ({selectedPlanId, plans}) => {
    const selectedPlan = plans.find(plan => {
      return plan.id === selectedPlanId
    })
    if (selectedPlan) {
      return (
        <Card plan={selectedPlan}>
          <div className="card-footer text-center rounded-bottom border-primary">
            <button
              className="btn btn-secondary"
              onClick={() => {
                setWasSelectedAddons([])
                setSelectedPlanId(null)
              }}
            >
              Change
            </button>
          </div>
        </Card>
      )
    } else {
      return (
        <button
          className="btn btn-secondary"
          onClick={() => {
            setWasSelectedAddons([])
            setSelectedPlanId(null)
          }}
        >
          See Available Plans
        </button>
      )
    }
  }

  const removeAddon = (arr, value) => {
    setSelectedAddons(arr.filter(addon => addon !== value))
  }

  const selectAddon = (arr, value) => {
    setSelectedAddons([...arr, value])
  }

  const Addon = ({addon}) => {
    const addon_id = addon.id.toString()
    const selected = selectedAddons.includes(addon_id)
    const wasSelected = wasSelectedAddons.includes(addon_id)

    return (
      <div className="col-12 border p-4 my-2">
        <div dangerouslySetInnerHTML={{__html: addon.description}} />
        <div className="row">
          {!wasSelected ? (
            <>
              <div className="col-sm-6 px-1 text-center">
                <button
                  className="btn m-1 my-0 btn-dark"
                  onClick={() => {
                    setWasSelectedAddons([...wasSelectedAddons, addon_id])
                    selectAddon(selectedAddons, addon_id)
                  }}
                >
                  {addon.accept_text ? addon.accept_text : 'Yes'}
                </button>
              </div>
              <div className="col-sm-6 px-1 text-center">
                <button
                  className="btn m-1 my-0 btn-dark"
                  onClick={() => {
                    setWasSelectedAddons([...wasSelectedAddons, addon_id])
                    removeAddon(selectedAddons, addon_id)
                  }}
                >
                  {addon.deny_text ? addon.deny_text : 'No'}
                </button>
              </div>
            </>
          ) : selected ? (
            <>
              <div className="col-sm-6 px-1 text-center">
                <span className="btn m-1 my-0 btn-success">
                  {addon.accept_text ? addon.accept_text : 'Yes'} <FontAwesomeIcon icon={faCheck} />
                </span>
              </div>
              <div className="col-sm-6 px-1 text-center">
                <button
                  className="btn m-1 my-0 btn-dark"
                  onClick={() => {
                    removeAddon(selectedAddons, addon_id)
                  }}
                >
                  {addon.deny_text ? addon.deny_text : 'No'}
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="col-sm-6 px-1 text-center">
                <button
                  className="btn m-1 my-0 btn-dark"
                  onClick={() => {
                    selectAddon(selectedAddons, addon_id)
                  }}
                >
                  {addon.accept_text ? addon.accept_text : 'Yes'}
                </button>
              </div>
              <div className="col-sm-6 px-1 text-center">
                <span className="btn m-1 my-0 btn-success">
                  {addon.deny_text ? addon.deny_text : 'No'} <FontAwesomeIcon icon={faCheck} />
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    )
  }

  const filteredAddons = (planId, addons) => {
    return addons.filter(addon => {
      return addon.plan_ids.includes(planId)
    })
  }

  const Addons = ({selectedPlanId, addons}) => {
    const relevantAddons = filteredAddons(selectedPlanId, addons)
    return relevantAddons.length > 0 ? (
      <div className="row justify-content-center mb-2 mx-auto" style={{maxWidth: '800px'}}>
        <div className="col-12 text-center">
          <h4>Available Addons</h4>
        </div>
        {relevantAddons.map(addon => (
          <Addon key={addon.id} addon={addon} />
        ))}
      </div>
    ) : null
  }

  const responseGivenOnAllAddons = (selectedPlanId, addons, wasSelectedAddons) => {
    const relevantAddons = filteredAddons(selectedPlanId, addons)
    const addonIds = relevantAddons.map(addon => {
      return addon.id.toString()
    })

    const areEqual = (first, second) => {
      if (first.length !== second.length) {
        return false
      }
      for (let i = 0; i < first.length; i++) {
        if (!second.includes(first[i])) {
          return false
        }
      }
      return true
    }

    return areEqual(addonIds, wasSelectedAddons)
  }
  const selectedPlan = plans.find(plan => {
    return plan.id === selectedPlanId
  })
  return (
    <>
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col text-center">
            <h1>Internet Plans</h1>
            <h2 className="h6">Choose from our high speed plans.</h2>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        {props.has_business_plans && ['business', 'residential'].includes(selectedCategory) ? (
          <div className="row justify-content-center">
            <div className="col-auto">
              <button
                className={`btn ${selectedCategory == 'residential' ? 'btn-success' : 'btn-primary'}`}
                onClick={() => setSelectedCategory('residential')}
              >
                Residential Plans {selectedCategory == 'residential' ? <FontAwesomeIcon icon={faCheck} /> : null}
              </button>
            </div>
            <div className="col-auto">
              <button
                className={`btn ${selectedCategory == 'business' ? 'btn-success' : 'btn-primary'}`}
                onClick={() => setSelectedCategory('business')}
              >
                Business Plans {selectedCategory == 'business' ? <FontAwesomeIcon icon={faCheck} /> : null}
              </button>
            </div>
          </div>
        ) : null}
        <div className="row no-gutters card-group justify-content-center">
          {selectedPlanId ? (
            <div className="col-auto p-2">
              <SelectedPlan selectedPlanId={selectedPlanId} plans={plans} />
            </div>
          ) : (
            filteredPlans(plans).map(plan => (
              <div key={plan.id} className="col-12 col-sm-6 col-md-4 p-2 mt-4">
                <Card plan={plan}>
                  <div className="card-footer text-center rounded-bottom border-primary">
                    <button
                      className="btn btn-secondary"
                      onClick={() => {
                        setWasSelectedAddons([])
                        setSelectedPlanId(plan.id)
                      }}
                    >
                      Select
                    </button>
                  </div>
                </Card>
              </div>
            ))
          )}
        </div>
        {selectedPlanId ? (
          <>
            <Addons addons={addons} selectedPlanId={selectedPlanId} />
            {responseGivenOnAllAddons(selectedPlanId, addons, wasSelectedAddons) && selectedPlan ? (
              <div className="row justify-content-center">
                <div className="col-auto">
                  <button className="btn btn-success" onClick={() => selectServicePlan(selectedPlanId)} disabled={submitting}>
                    Confirm
                  </button>
                </div>
              </div>
            ) : null}
          </>
        ) : null}

        <div className="row mt-4">
          <div className="col text-center">
            <small>* first monthly payment will be made with the same card upon service installation.</small>
          </div>
        </div>
      </div>
    </>
  )
}
export default Plans
