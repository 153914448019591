import React, {useState} from 'react'
import '../stylesheets/Signup.scss'
import {formatInTimeZone} from 'date-fns-tz'

const formatTimestamp = (timestamp, timezone) => {
  const date = new Date(timestamp * 1000);
  return formatInTimeZone(date, timezone, 'MMMM dd, yyyy h:mm a (zz)');
}
const OverdueBilling = ({invoices, timezone, entity, params}) => {
  if (invoices === null) {
    return <div className="container py-4"><h1>Loading...</h1></div>
  }
  if (invoices.length === 0) {
    return <div className="container py-4"><h1>No overdue invoices found.</h1></div>
  }
  const ascending_created = params.direction === "asc" && params.sort_by === 'created'
  const ascending_due_date = params.direction === "asc" && params.sort_by === 'due_date'
  return (
    <div className="px-4 py-4">
      <h1>Overdue Invoices</h1>
      <table className="table table-striped">
        <thead>
          <tr>
            <th className="border">State</th>
            <th className="border">Total</th>
            <th className="border">Invoice ID</th>
            <th className="border">Stripe Customer</th>
            <th className="border">CRM Customer</th>
            <th className="border">Tags</th>
            <th className="border"><a href={`?sort_by=created&direction=${ascending_created ? "desc" : "asc" }`}>Created {params.sort_by === 'created' ? ascending_created ? <i className="icon-arrow-down15 mr-2"></i>  : <i className="icon-arrow-up15 mr-2"></i>  : ''}</a></th>
            <th className="border"><a href={`?sort_by=due_date&direction=${ascending_due_date ? "desc" : "asc" }`}>Due Date {params.sort_by === 'due_date' ? ascending_due_date ? <i className="icon-arrow-down15 mr-2"></i>  : <i className="icon-arrow-up15 mr-2"></i>  : ''}</a></th>
            <th className="border">Days Past Due</th>
            {/* <th>Actions</th> */}
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice, index) => {
            const created = formatTimestamp(invoice.created, timezone)
            const due_date = invoice.due_date ? formatTimestamp(invoice.due_date, timezone) : null

            const determined_due_date = invoice.due_date ? invoice.due_date : invoice.created
            // Date.now() returns milliseconds, so we divide by 1000 to get seconds
            // 86400 seconds in a day, so we divide by that to get days
            const days_past_due = Math.floor((Date.now() / 1000 - determined_due_date) / 86400)
            let state
            if (invoice.attempted && !invoice.next_payment_attempt) {
              state = <span className="text-danger">Failed</span>;
            } else if (invoice.paid_out_of_band) {
              state = <span className="text-warning">Pending</span>;
            } else if (invoice.next_payment_attempt) {
              state = <span className="text-warning">Retrying</span>;
            } else {
              state = <span className="text-danger">Past Due</span>;
            }
            return (
              <tr className={index % 2 === 0 ? 'bg-dark-100' : ''} key={invoice.id}>
                <td className="border" style={{padding: '0.5rem'}}>{state}</td>
                <td className="border" style={{padding: '0.5rem'}}>${(invoice.total / 100).toFixed(2)}</td>
                <td className="border" style={{padding: '0.5rem'}}>
                  <a href={`https://dashboard.stripe.com/invoices/${invoice.id}`} target="_blank" rel="noopener noreferrer">
                    {invoice.id}
                  </a>
                </td>
                <td className="border" style={{padding: '0.5rem'}}>
                  <a href={`https://dashboard.stripe.com/customers/${invoice.customer}`} target="_blank" rel="noopener noreferrer">
                    {invoice.customer_email}
                  </a>
                </td>
                <td className="border" style={{padding: '0.5rem', width: '10rem'}}>{invoice.crm_client_uid ? <a className="d-inline-block btn btn-primary p-1 text-md m-1" href={`${entity.uisp_url}/crm/client/${invoice.crm_client_uid}`}>UCRM</a> : ""} {invoice.jarvis_customer_uid ? <a className="inline btn btn-primary p-1 small m-1" href={`/crm/${entity.name}/customers/${invoice.jarvis_customer_uid}`}>Jarvis</a> : ""}</td>
                <td className="border" style={{padding: '0.5rem'}}>
                  {invoice.customer_tags && invoice.customer_tags.map((tag) => {
                    return <span key={tag.name} className="badge badge-secondary text-sm ml-1" style={{fontSize: '0.5rem', backgroundColor: tag.color}}>{tag.name}</span>
                  })}
                </td>
                <td className="border" style={{padding: '0.5rem'}}>{created}</td>
                <td className="border" style={{padding: '0.5rem'}}>{due_date || <span className='opacity-50'>{created}</span>}</td>
                <td className="border" style={{padding: '0.5rem'}}>{days_past_due}</td>
                {/* <td><a href={`crm/invoices/${invoice.id}`}>View</a></td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  )
}

const Invoices = ({initial_pagination, initial_invoices, timezone, entity, params}) => {
  const [invoices, setInvoices] = useState(initial_invoices)
  const [pagination, setPagination] = useState(initial_pagination)

  const fetchInvoices = (startingAfter = null, endingBefore = null) => {
    let url = `/crm/${entity.name}/invoices.json`
    if (startingAfter) {
      url += `?starting_after=${startingAfter}`
    } else if (endingBefore) {
      url += `?ending_before=${endingBefore}`
    }

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
    })
      .then(response => response.json())
      .then(data => {
        setInvoices(data.invoices)
        setPagination(data.pagination)
      }).catch(error => console.error('Error fetching invoices:', error))
  }
  return (
    <div>
      <OverdueBilling invoices={invoices} timezone={timezone} entity={entity} params={params} />
      <div className='text-center mb-4'>
        {pagination.previous_ending_before && <button className="btn btn-indigo btn-sm" onClick={() => fetchInvoices(null, pagination.previous_ending_before)}>Previous</button>}
        {pagination.next_starting_after && <button className="btn btn-indigo btn-sm" onClick={() => fetchInvoices(pagination.next_starting_after)}>Next</button>}
      </div>
    </div>
  )
}

export default Invoices
