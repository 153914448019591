import React from 'react'

const Complete = ({ signup, signup_complete, ...props }) => {
  return (
    <div className="container-fluid px-5">
      <div className="row mb-4">
        <div className="col">
            <div dangerouslySetInnerHTML={{__html: signup_complete}} />
        </div>
      </div>
    </div>
  )
}

export default Complete