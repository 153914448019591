import React, {useState, useEffect, useRef} from 'react'
import Calendar from 'react-calendar'

import {
  parseISO,
  getMinutes,
  setMinutes,
  getHours,
  setHours,
  add,
  isSaturday,
  isFriday,
} from 'date-fns'
import {format, toDate, formatInTimeZone} from 'date-fns-tz'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBan, faCheck, faArrowRight} from '@fortawesome/free-solid-svg-icons'
import { currentDayTimeSlots, tileClassName, tileDisabled } from '../event_scheduler/EventComponents'

const Schedule = ({
  signup,
  setSignup,
  setServerErrors,
  csrf_token,
  name,
  scheduling_hours,
  todays_date,
  schedule_minimum_days,
  disabled_dates,
  calendar_url,
  calendar_entities,
  no_calendar_text,
  timezone,
  ...props
}) => {
  const todaysDate = new Date(todays_date)
  const defaultDate = setHours(todaysDate, 0)

  const maxDate = add(todaysDate, {days: 60})
  const minDatePlusWeekend = () => {
    const min = parseInt(schedule_minimum_days)
    if (min < 3 && isSaturday(todaysDate)) {
      return add(todaysDate, {days: (parseInt(schedule_minimum_days) || 5) + 1})
    } else if (min < 3 && isFriday(todaysDate)) {
      return add(todaysDate, {days: (parseInt(schedule_minimum_days) || 5) + 2})
    } else {
      return add(todaysDate, {days: parseInt(schedule_minimum_days) || 5})
    }
  }
  const minDate = minDatePlusWeekend()

  const [timeSlots, setTimeSlots] = useState(null)
  const [dateValue, setDateValue] = useState(defaultDate)
  const [submitting, setSubmitting] = useState(null)

  useEffect(() => {
    if (!signup.uuid) props.goToStep(1)
  }, [])

  function encodeQueryData(data) {
    const ret = []
    for (let d in data) ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
    return ret.join('&')
  }

  useEffect(() => {
    const controller = new AbortController()
    const getJobs = async () => {
      const query = {
        blockoutEntity: name,
        calendarEntity: name,
        timeslot: '[T]imeSlot'
      }

      const queryString = encodeQueryData(query)

      const response = await fetch(`/scheduling/time_slots?${queryString}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf_token,
        },
      })
      const respData = await response.json()
      if (respData !== null) {
        setTimeSlots(respData)
        setSubmitting(false)
        if (respData.errors) {
          setServerErrors(respData.errors)
        }
      }
    }

    getJobs()

    return function cancel() {
      controller.abort()
    }
  }, [name])

  const saveInstallationDate = async selectedDate => {
    if (submitting) {
      return
    }
    const formatString = 'yyyy-MM-dd HH:mm:ss'
    setServerErrors(null)
    setSubmitting(true)
    const response = await fetch(`/service_signup/${name}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token,
      },
      body: JSON.stringify({
        service_signup: {
          ...signup,
          signup_state: 'schedule',
          installation_date: toDate(format(selectedDate, 'yyyy-MM-dd HH:mm:ss')),
        },
      }),
    })

    let respData = await response.json()
    if (respData !== null) {
      setSignup(respData.data)
      setSubmitting(false)
      if (respData.errors) {
        setServerErrors(respData.errors)
      } else {
        props.nextStep()
      }
    }
  }
  const SelectedDateSummaryView = ({selectedDate}) => {
    const detailsRef = useRef()

    // useEffect(() => {
    //   // Test suite fails without this check
    //   // detailsRef.current.scrollIntoView() is not a function
    //   if (typeof detailsRef.current.scrollIntoView === 'function') {
    //     detailsRef.current.scrollIntoView()
    //   }
    // }, [selectedDate])

    return (
      <div id="installation-details" className="container-fluid">
        <div className="row justify-content-center">
          <div className="card mt-2">
            <div className="card-body">
              <h5 className="card-title" ref={detailsRef}>
                Installation Details
              </h5>
              {selectedDate && getHours(selectedDate) !== 0 ? (
                <>
                  {signup.uuid ? (
                    <>
                      <p>
                        <b>Date:</b> {formatInTimeZone(selectedDate, timezone, 'MMMM dd, yyyy')}
                        <br />
                        <b>Time:</b> {formatInTimeZone(selectedDate, timezone, 'h:mm a (zz)')}
                        <br />
                        <br />
                        <b>Location:</b> {signup.address_street} {signup.address_city}, {signup.address_state} {signup.address_zip}
                      </p>
                      <button className="btn btn-primary my-3" onClick={() => saveInstallationDate(selectedDate)} disabled={submitting}>
                        <span>Confirm Installation Date and Proceed</span> <FontAwesomeIcon icon={faArrowRight} />
                      </button>
                    </>
                  ) : (
                    <>
                      <button className="btn btn-primary" onClick={() => props.goToStep(1)}>
                        Missing details, please return to account step.
                      </button>
                    </>
                  )}
                </>
              ) : (
                <button className="btn btn-dark my-3">
                  <span>Select Installation Date and Time</span> <FontAwesomeIcon icon={faBan} />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const isTheSameHourAndMinutes = (event, compare) => {
    return getHours(event) === getHours(compare) && getMinutes(event) === getMinutes(compare)
  }

  const SelectedHourView = ({timeSlots, selectedDate}) => {
    const selectHour = event => {
      const dateWithEventHour = setHours(selectedDate, getHours(event))
      const dateWithEventMinutes = setMinutes(dateWithEventHour, getMinutes(event))
      setDateValue(dateWithEventMinutes)
    }

    return (
      <div id="time-selection" className="container-fluid">
        {formatInTimeZone(selectedDate, timezone, 'MMMM dd, yyyy')}
        <div className="row no-gutters">
          <div className="col-12 center">
            {currentDayTimeSlots(timeSlots, selectedDate).length > 0 ? (
              <div className="row no-gutters">
                {currentDayTimeSlots(timeSlots, selectedDate).map(timeSlot => {
                  const parsedDate = parseISO(timeSlot.start)
                  return (
                    <div key={timeSlot.start} className="col-6 col-md-4 px-1 py-1">
                      {!timeSlot.available ? (
                        <div className="btn btn-dark w-100">
                          {formatInTimeZone(parsedDate, timezone, 'h:mm a')} <FontAwesomeIcon icon={faBan} />
                        </div>
                      ) : isTheSameHourAndMinutes(parsedDate, selectedDate) ? (
                        <div className="btn btn-success w-100">
                          {formatInTimeZone(parsedDate, timezone, 'h:mm a')} <FontAwesomeIcon icon={faCheck} />
                        </div>
                      ) : (
                        <div className="btn btn-primary w-100" onClick={() => selectHour(parsedDate)}>
                          {formatInTimeZone(parsedDate, timezone, 'h:mm a')}
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            ) : (
              <div className="btn btn-success">No Hours Available...</div>
            )}
          </div>
        </div>
        <SelectedDateSummaryView selectedDate={selectedDate} />
      </div>
    )
  }

  return (
    <>
      <div className="container-fluid px-md-5">
        <div className="row mb-4">
          <div className="col text-center">
            {timeSlots && calendar_entities ? (
              <div id="calendar-wrapper">
                <Calendar
                  calendarType="US"
                  value={dateValue}
                  onChange={setDateValue}
                  tileClassName={props => tileClassName({...props, minDate: minDate, maxDate: maxDate, timeSlots: timeSlots})}
                  tileDisabled={props => tileDisabled({...props, minDate: minDate, maxDate: maxDate, timeSlots: timeSlots})}
                  minDate={minDate}
                  maxDate={maxDate}
                  minDetail="month"
                  next2Label={null}
                  prev2Label={null}
                />
                <br />
                {tileDisabled({date: dateValue, view: 'month', minDate: minDate, maxDate: maxDate, timeSlots: timeSlots}) ? null : <SelectedHourView timeSlots={timeSlots} selectedDate={dateValue} />}
              </div>
            ) : calendar_entities ? (
              <div>
                <div className="spinner-border text-primary" role="status"></div>
                <div>Loading Calendar...</div>
              </div>
            ) : (
              <div>
                {<div dangerouslySetInnerHTML={{__html: no_calendar_text}} />}
                <button className="btn btn-primary my-3" onClick={() => props.nextStep()}>
                  <span>Proceed</span> <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default Schedule
